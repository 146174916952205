import "antd/dist/antd.css";
import React from 'react'
import Connex from "@vechain/connex";
import { useCallback, useState, useEffect } from "react";
import { Layout, Row, Col, Input, Button, Divider, Typography, Checkbox, Space } from "antd";
import CouponCodes from './CouponCodes'
const abi = require('./abi.json')

const { Content } = Layout
const { Text, Title } = Typography

const NETWORKS = {
  test: {
    node: "https://testnet.veblocks.net",
    network: "test",
    voteosAddress: "0x622d03e8f70fa1be73c40c4ecf356d6e95a6acab",
    delegateUrl: 'https://api-preview.voteos.com/fee/delegate',
    couponUrl: 'https://api-preview.voteos.com/coupon/'
  },
  main: {
    node: "https://mainnet.veblocks.net",
    network: "main",
    voteosAddress: '0x95dd3784969d40ac2a8a9b6933df82b44715e200',
    delegateUrl: 'https://api.voteos.com/fee/delegate',
    couponUrl: 'https://api.voteos.com/coupon/'
  },
}
export default function App () {
  const [balance, setBalance] = useState('?');
  const [network, setNetwork] = useState('test');
  const [newBalance, setNewBalance] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [address, setAddress] = useState(window.localStorage.getItem('ballot.admin.address') || '');
  const [roleAddress, setRoleAddress] = useState(window.localStorage.getItem('ballot.admin.roleAddress') || '');
  const [error, setError] = useState();
  const [txId, setTxId] = useState();
  const [loading, setLoading] = useState();
  const [roles, setRoles] = useState([])
  const [connex, setConnex] = useState(new Connex(NETWORKS[network]))

  const changeNetwork = (network) => () => setNetwork(network);

  useEffect(() => {
    setConnex(new Connex(NETWORKS[network]))
  }, [network])

  const getRoles = useCallback(async (roleAddress) => {
    const { decoded: { 0: bot } } = await connex.thor.account(NETWORKS[network].voteosAddress).method(abi.find(({ name }) => name === "BOT_ROLE")).call();
    const { decoded: { 0: admin } } = await connex.thor.account(NETWORKS[network].voteosAddress).method(abi.find(({ name }) => name === "ADMIN_ROLE")).call();

    const { decoded: { 0: isBot } } = await connex.thor.account(NETWORKS[network].voteosAddress).method(abi.find(({ name }) => name === "hasRole")).call(bot, roleAddress);
    const { decoded: { 0: isAdmin } } = await connex.thor.account(NETWORKS[network].voteosAddress).method(abi.find(({ name }) => name === "hasRole")).call(admin, roleAddress);
    setRoles([
      { id: admin, name: 'admin', granted: isAdmin },
      { id: bot, name: 'bot', granted: isBot },
    ])
  }, [connex, network])

  const testAccess = useCallback(async (roleAddress) => {
    const abiSetBalance = abi.find(({ name }) => name === "setBallotBalance");
    const clause = await connex.thor
      .account(address)
      .method(abiSetBalance)
      .asClause(newBalance)
    const explainedTransaction = await connex.thor.explain([clause])
      .caller(roleAddress)
      .execute()

    console.log(address, explainedTransaction)
  }, [address, newBalance, connex])

  const getBalanceFor = useCallback(async (address) => {
    const abiBallotBalance = abi.find(({ name }) => name === "ballotBalance");
    try {
      const {
        decoded: { 0: balance }
      } = await connex.thor
        .account(address)
        .method(abiBallotBalance)
        .call();

      setBalance(balance);
      setNewBalance(balance)
    } catch (err) {
      setBalance('?');
      console.log(err);
    }
  }, [connex])

  const getSubscriptionStatusFor = useCallback(async (address) => {
    const abiSubscription = abi.find(({ name }) => name === "isSubscribed");
    try {
      const {
        decoded: { 0: subscription }
      } = await connex.thor
        .account(address)
        .method(abiSubscription)
        .call();
      setIsSubscribed(subscription);
    } catch (err) {
      setIsSubscribed(false);
      console.log(err);
    }
  }, [connex])

  const updateBalance = useCallback(async () => {
    setError();
    setLoading(true);
    try {
      const abiSetBalance = abi.find(({ name }) => name === "setBallotBalance");
      const clause = await connex.thor
        .account(address)
        .method(abiSetBalance)
        .asClause(newBalance)
      const { txid } = await connex.vendor.sign('tx', [clause])
        .delegate(NETWORKS[network].delegateUrl)
        .comment(`set new balance to ${newBalance}`)
        .request()
      await setTxId(txid);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [newBalance, address, connex, network])

  const updateSubscription = useCallback(async () => {
    setError();
    setLoading(true);
    try {
      const abiSetSubscribed = abi.find(({ name }) => name === "setSubscribed");
      const clause = await connex.thor
        .account(address)
        .method(abiSetSubscribed)
        .asClause(isSubscribed)
      const { txid } = await connex.vendor.sign('tx', [clause])
        .delegate(NETWORKS[network].delegateUrl)
        .comment(`set new balance to ${isSubscribed}`)
        .request()
      await setTxId(txid);
    } catch (err) {
      setIsSubscribed(!isSubscribed)
      setError(err.message);
    }
    setLoading(false);
  }, [isSubscribed, address, connex, network])

  const grantRole = useCallback((id) => async () => {
    setError();
    setLoading(true);
    try {
      const abiGrantRole = abi.find(({ name }) => name === "grantRole");
      const clause = await connex.thor
        .account(NETWORKS[network].voteosAddress)
        .method(abiGrantRole)
        .asClause(id, roleAddress)
      const { txid } = await connex.vendor.sign('tx', [clause])
        .delegate(NETWORKS[network].delegateUrl)
        .comment(`grant access for ${roleAddress}`)
        .request()
      await setTxId(txid);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [roleAddress, connex, network])

  const revokeRole = useCallback((id) => async () => {
    setError();
    setLoading(true);
    try {
      const abiRevokeRole = abi.find(({ name }) => name === "revokeRole");
      const clause = await connex.thor
        .account(NETWORKS[network].voteosAddress)
        .method(abiRevokeRole)
        .asClause(id, roleAddress)
      const { txid } = await connex.vendor.sign('tx', [clause])
        .delegate(NETWORKS[network].delegateUrl)
        .comment(`revoke access for ${roleAddress}`)
        .request()
      await setTxId(txid);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [roleAddress, connex, network])

  useEffect(() => {
    getBalanceFor(address);
    getSubscriptionStatusFor(address);
    window.localStorage.setItem('ballot.admin.address', address)
  }, [getBalanceFor, getSubscriptionStatusFor, address]);

  useEffect(() => {
    getRoles(roleAddress);
    testAccess(roleAddress)
    window.localStorage.setItem('ballot.admin.roleAddress', roleAddress)
  }, [getRoles, testAccess, roleAddress]);

  return (
    <Content style={{ padding: '40px' }}>
      <Row gutter={[32, 32]} align="top">
        <Col span={24} align="center">
          <Title style={{ textAlign: 'center'}}>VOTEOS Ballot Admin</Title>
          <Button type={network === 'main' ? 'primary' : 'secondary'} onClick={changeNetwork('main')}>MainNet</Button><Button type={network === 'test' ? 'primary' : 'secondary'} onClick={changeNetwork('test')}>TestNet</Button>
        </Col>
        <Col span={24} align="center">
          <Divider>Ballot Admin for Organization</Divider>
          <Input
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder="0x23a65394d596c8fb504242255ddaae971b90ec93"
          />
          <h2>{balance}</h2>
        </Col>
        <Col span={12} align="center">
          <Space direction="vertical">
            <Text strong style={{ marginBottom: 8 }}>New Balance</Text>
            <Space>
              <Input
                onChange={(e) => setNewBalance(e.target.value)}
                value={newBalance}
              />
              <Button loading={loading} type='primary' onClick={updateBalance} block>Update Balance</Button>
            </Space>
          </Space>
        </Col>

        <Col span={12} align="center">
          <Text strong style={{ display: 'block', textAlign: 'center', paddingBottom: 8}}>Subscription</Text>
          <Space>
            <Checkbox checked={isSubscribed} onChange={(e) => setIsSubscribed(e.target.checked)}>Subscription active</Checkbox>
            <Button loading={loading} type='primary' onClick={updateSubscription} block>Update Subscription</Button>
          </Space>
        </Col>
        {!!txId && (
          <Col span={24} align="center">
            Transaction Id:
            <br />
            <a
              href={`https://explore-testnet.vechain.org/transactions/${txId}`}
              target="_blank"
              rel="noreferrer"
            >
              {txId}
            </a>
          </Col>
        )}
        {!!error && (
          <Col span={24} align="center">
            <b>{error}</b>
          </Col>
        )}

        <Col span={24} align="center">
          <Divider>RBC</Divider>

          <Row gutter={[16, 16]}>
            {roles.map(({ id, name, granted }) => (
              <React.Fragment key={id}>
                <Col span={8}>{name}</Col>
                <Col span={8}>{id}</Col>
                <Col span={8}>
                  {granted && <Button size='small' danger onClick={revokeRole(id)}>Access granted, revoke</Button>}
                  {!granted && <Button size='small' onClick={grantRole(id)}>No access, grant now</Button>}
                </Col>
              </React.Fragment>
            ))}
          </Row>

          <Input
            onChange={(e) => setRoleAddress(e.target.value)}
            value={roleAddress}
          />

        </Col>
        <Col span={24} align="center">
          <Divider>Coupon codes</Divider>
          <CouponCodes couponUrl={NETWORKS[network].couponUrl} />
        </Col>
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
      </Row>
    </Content>
  );
}
