import { useRef } from 'react'
import { Button, Input, Space, Table, Typography, message } from 'antd'
import { SearchOutlined, DeleteOutlined, CopyOutlined, ReloadOutlined } from '@ant-design/icons'

const CodeTable = ({ data = [], selectedRows, onChangeSelection, onDelete, onRefresh, ...restProps }) => {
  const searchInput = useRef(null)
  const users = data.length ? data.reduce((acc, cur) => {
    if (cur.generatedBy && !acc.includes(cur.generatedBy)) {
      acc.push(cur.generatedBy)
    }
    return acc
  }, []) : []

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
  }
  const handleReset = (clearFilters) => {
    clearFilters()
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (code) => <Typography.Text code copyable>{code}</Typography.Text>,
      ...getColumnSearchProps('code')
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      ...getColumnSearchProps('note')
    },
    {
      title: 'Ballots',
      dataIndex: 'amount',
      key: 'ballots',
      align: 'right',
      sorter: (a, b) => Number(a.amount) - Number(b.amount),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Generated at',
      dataIndex: 'generatedAt',
      key: 'date',
      sorter: (a, b) => new Date(a.generatedAt) - new Date(b.generatedAt),
      sortDirections: ['descend', 'ascend'],
      render: (date) => new Date(date).toLocaleString(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Generated by',
      dataIndex: 'generatedBy',
      key: 'user',
      filters: users?.map(user => ({ text: user, value: user })),
      onFilter: (value, record) => record.generatedBy === value
    },
    {
      title: 'Redeemed by organization',
      dataIndex: 'redeemedBy',
      key: 'redeemed',
      filters: [{ text: 'Redeemed', value: 'Yes' }, { text: 'Unredeemed', value: 'No' }],
      onFilter: (value, record) => value === 'Yes' ? record.redeemedBy : !record.redeemedBy
    },
    {
      title: 'Redeemed at',
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      sorter: (a, b) => new Date(a.redeemedAt || new Date()) - new Date(b.redeemedAt || new Date()),
      sortDirections: ['descend', 'ascend'],
      render: (date) => date ? new Date(date).toLocaleString() : '-'
    }
  ]

  const renderTitle = () => {
    function copy (values) {
      navigator.clipboard.writeText(values.map(({ code }) => code).join('\n'))
      message.success(values.length + ' code(s) copied to clipboard')
    }
    return (
      <Space size='large'>
        <Button
          type='primary'
          icon={<CopyOutlined />}
          disabled={!selectedRows || !selectedRows.length}
          onClick={() => copy(selectedRows)}
        >
          Copy selected codes
        </Button>
        <Button
          type='danger'
          icon={<DeleteOutlined />}
          disabled={!selectedRows || !selectedRows.length}
          onClick={() => onDelete(selectedRows)}
        >
          Delete selected codes
        </Button>
        <Button
          icon={<ReloadOutlined />}
          type='secondary'
          onClick={onRefresh}
        >
          Refresh coupon data
        </Button>
      </Space>
    )
  }
  return (
    <Table
      title={renderTitle}
      columns={columns}
      dataSource={data}
      pagination={data.length > 10}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedRows?.map(coupon => coupon.code),
        onChange: onChangeSelection
      }}
      rowKey='code'
      onRow={(record, rowIndex) => {
        return {
          onDoubleClick: (event) => {
            const newSelectedRows = selectedRows.some(row => row.code === record.code) ? selectedRows.filter(row => row.code !== record.code) : [...(selectedRows || []), record]
            onChangeSelection(null, newSelectedRows)
          }
        }
      }}
      {...restProps}
    />
  )
}

export default CodeTable
