import { useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Input, InputNumber, Button, message } from 'antd'
import Table from './Table'

const CouponCodes = ({ couponUrl }) => {
  const [token, setToken] = useState('')
  const [coupons, setCoupons] = useState([])
  const [newCoupons, setNewCoupons] = useState([])
  const [loading, setLoading] = useState(false)

  const onChangeToken = (e) => {
    if (e.target.value === '') {
      window.localStorage.removeItem('ballot.admin.gitlabToken')
    }
    setToken(e.target.value)
  }

  useEffect(() => {
    const token = window.localStorage.getItem('ballot.admin.gitlabToken')
    if (token) {
      setToken(token)
    }
  }, [])

  const getCouponData = useCallback(async () => {
    setLoading(true)
    try {
      const result = await window.fetch(couponUrl + 'list', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
      })
      const data = await result.json()
      if (data.error) {
        message.error(data.error)
        return
      }
      setCoupons(data)
      window.localStorage.setItem('ballot.admin.gitlabToken', token)
    } catch (err) {
      console.log('err', err)
    }
    setLoading(false)
  }, [couponUrl, token])

  useEffect(() => {
    if (token) {
      getCouponData()
    }
  }, [token, getCouponData])

  const onFinish = async ({ codeAmount, ballotAmount, note, errorFields }) => {
    if (errorFields) {
      return
    }

    const result = await window.fetch(couponUrl + 'generate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token,
        codeAmount,
        ballotAmount,
        note
      })
    })
    const data = await result.json()
    if (data.error) {
      message.error(data.error)
      return
    }
    setNewCoupons(data)
    getCouponData(token)
  }
  const onFinishFailed = (value) => {
    console.log('value', value)
  }

  const onDelete = async (selectedRows) => {
    const codes = selectedRows.map((row) => row.code)
    setLoading(true)
    try {
      await window.fetch(couponUrl + 'delete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          codes
        })
      })
      message.success(codes.length + ' code(s) deleted')
      getCouponData(token)
    } catch (error) {
      console.log('error', error)
      message.error('Error deleting codes')
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Form
          name='couponForm'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8, align: 'left' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >

          <Form.Item
            label='Gitlab Token'
            rules={[{ required: true, message: 'Please input your access token' }]}
          >
            <Input placeholder='access token' value={token} onChange={onChangeToken} type='password' allowClear />
          </Form.Item>
          <Form.Item
            label='Amount of coupons to generate'
            name='codeAmount'
            initialValue={1}
          >
            <InputNumber min={1} max={1500} defaultValue={25} />
          </Form.Item>
          <Form.Item
            label='Amount of ballots each coupon will grant'
            name='ballotAmount'
            initialValue={25}
          >
            <InputNumber min={1} max={500} />
          </Form.Item>
          <Form.Item
            label='Optional note to describe the purpose of the coupons'
            name='note'
          >
            <Input placeholder='note' />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
            <Button type='primary' htmlType='submit' block disabled={!token}>
              Generate coupon codes
            </Button>
          </Form.Item>
        </Form>
      </Col>
      {token && (
        <Col span={24}>
          <Table
            data={coupons}
            loading={loading}
            selectedRows={newCoupons}
            onChangeSelection={(record, selectedRows) => setNewCoupons(selectedRows)}
            onDelete={onDelete}
            onRefresh={getCouponData}
          />
        </Col>
      )}
    </Row>
  )
}

export default CouponCodes
